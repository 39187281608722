import React, { ReactNode } from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { MessagesKey } from '@/types/translations.types';
import { cn } from '@/utils/styles.utils';

interface Props {
  textId?: MessagesKey;
  text?: string | ReactNode;
  size?: 's' | 'm';
}

export const InfoMessage = (props: Props) => {
  const { textId, text, size = 'm' } = props;
  const pxSize = size === 'm' ? 24 : 16;
  return (
    <div className="flex">
      <Icon
        size={pxSize}
        name="info"
        color="grey-600"
        className={cn('shrink-0', {
          'mr-1': size === 's',
          'mr-2': size === 'm',
        })}
      />
      <Typography
        id={textId}
        variant="body_m"
        color="grey-standard"
        style={{
          lineHeight: `${pxSize}px`,
        }}
      >
        {text}
      </Typography>
    </div>
  );
};
