import { useState } from 'react';

import { Icon } from '@/components/_atoms/Icon';
import { Typography } from '@/components/_atoms/Typography';
import { useBySize } from '@/hooks/useBySize';

interface Props {
  index: number;
  title: string;
  description: string;
}

export const HelpParameter = ({ index, title, description }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useBySize({
    mobile: true,
    tablet: false,
  });

  const display = !isMobile || isOpen ? 'inline-block' : 'none';

  return (
    <li
      className="grid gap-2 border-b border-grey-400 px-6 py-4 [grid-template-columns:auto_1fr] tablet:border-0 tablet:p-0"
      onClick={() => setIsOpen((v) => !v)}
    >
      <Typography as="span" bold>
        {index + 1}.
      </Typography>
      <div className="flex items-center justify-between">
        <Typography as="span" bold>
          {title}
        </Typography>
        <Icon name={isOpen ? 'chevronUp' : 'chevronDown'} size={16} color="grey-600" className="tablet:hidden" />
      </div>
      <Typography
        as="span"
        className="col-start-2"
        color="grey-standard"
        style={{
          display,
        }}
      >
        {description}
      </Typography>
    </li>
  );
};
