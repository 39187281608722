import { CookieValueTypes, getCookie, removeCookies, setCookie } from 'cookies-next';
import { GetServerSidePropsContext } from 'next';

import { addDays } from '@/utils/time.utils';

import { SELECTED_TYRE_CONFIG_COOKIE_KEY } from './selectedTyre.constants';
import { SelectedTyreValue } from './selectedTyre.types';

function isSelectedTyreType(data: any): data is SelectedTyreValue {
  //TODO add zod
  return 'size' in data && 'season' in data && Array.isArray(data.season);
}

const parseSelectedTyreCookie = (str: CookieValueTypes) => {
  if (typeof str !== 'string') {
    return null;
  }
  try {
    const value = JSON.parse(str);
    if (isSelectedTyreType(value)) {
      return value;
    }
  } catch {
    return null;
  }
  return null;
};

export const setSelectedTyreCookie = (data: SelectedTyreValue) => {
  setCookie(SELECTED_TYRE_CONFIG_COOKIE_KEY, data, {
    expires: addDays(new Date(), 365),
  });
};

export const removeSelectedTyreCookie = () => {
  removeCookies(SELECTED_TYRE_CONFIG_COOKIE_KEY);
};

export const getSelectedTyreCookie = (context: GetServerSidePropsContext) => {
  const cookie = parseSelectedTyreCookie(getCookie(SELECTED_TYRE_CONFIG_COOKIE_KEY, context));
  return cookie;
};
