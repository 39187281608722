import React, { useId } from 'react';

import { cn } from '@/utils/styles.utils';

import { InputBox } from '../InputBox';
import { NativeSelect } from '../NativeSelect';
import { NativeSelectProps } from '../NativeSelect/NativeSelect';

type Props = {
  label?: string;
  value: string | undefined;
  disabled?: boolean;
  variant?: 'outline' | 'solid' | 'empty';
  size?: 's' | 'm' | 'l';
  options: NativeSelectProps['options'];
  allowNull?: boolean;
  onChange: (value: string | undefined) => void;
  className?: string;
};

export const Select = (props: Props) => {
  const {
    label,
    value,
    onChange,
    options,
    disabled = false,
    variant = 'solid',
    size = 'l',
    allowNull = true,
    className,
  } = props;
  const flatOptions = options.flatMap((option) => (option.options ? option.options : option));
  const selectedOption = flatOptions.find((option) => option.value === value);
  const displayValue = selectedOption?.label;
  const id = useId();
  return (
    <InputBox
      id={id}
      isActive={!!value}
      variant={variant}
      value={displayValue}
      disabled={disabled}
      label={label}
      size={size}
      icon="chevronDownFilled"
      select
    >
      <NativeSelect
        label={label}
        id={id}
        className={cn(
          'cursor-pointer appearance-none text-body-m',
          {
            'text-transparent transition-none disabled:text-transparent': !value,
          },
          className,
        )}
        allowNull={allowNull}
        value={value}
        onChange={onChange}
        options={options}
        disabled={disabled}
      />
    </InputBox>
  );
};
