import { useMemo } from 'react';

import { useTranslations } from '@/hooks/useTranslations';
import { Make } from '@/network/GeneratedApi';

export const useGroupSelectCarProducers = (producers: Make[]) => {
  const { t } = useTranslations();
  const groups = useMemo(() => {
    const popular: { value: string; label: string }[] = [];
    const rest: { value: string; label: string }[] = [];
    producers.forEach((producer) => {
      if (producer.is_popular) {
        popular.push({ value: producer.slug, label: producer.shop_name || '' });
      } else {
        rest.push({ value: producer.slug, label: producer.shop_name || '' });
      }
    });

    return [
      {
        label: t('selectCar.producers.popularGroup'),
        options: popular,
      },
      {
        label: t('selectCar.producers.restGroup'),
        options: rest,
      },
    ];
  }, [producers]);

  return groups;
};
