import { useEffect } from 'react';

import { useAppStore } from '@/store';

import { setSelectedTyreCookie } from './selectedTyre.utils';

interface Props {
  size: string | null;
  season: string[] | null;
}

export const useSetSelectedTyre = (data: Props) => {
  const setSelectedTyre = useAppStore((store) => store.setSelectedTyre);
  const { size = null, season } = data;
  useEffect(() => {
    if (size) {
      const newData = { size, season: season || [] };
      setSelectedTyre(newData);
      setSelectedTyreCookie(newData);
    }
  }, [size, season]);
};
