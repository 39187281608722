import * as RadioGroupPrimitives from '@radix-ui/react-radio-group';
import React from 'react';

import { RadioIndicator } from '@/components/_atoms/RadioIndicator';
import { cn } from '@/utils/styles.utils';

interface RadioGroupItem<T extends string> {
  label?: string;
  children?: React.ReactNode;
  value: T;
  disabled?: boolean;
  bold?: boolean;
}

export interface RadioGroupProps<T extends string> {
  value?: T;
  onChange: (value: T) => void;
  options: RadioGroupItem<T>[];
  direction?: 'row' | 'column';
  size?: 's' | 'm';
}

export const RadioGroup = <T extends string>(props: RadioGroupProps<T>) => {
  const { value, onChange, options, direction = 'row', size = 'm' } = props;

  return (
    <RadioGroupPrimitives.Root value={value} onValueChange={onChange}>
      <div
        className={cn('flex flex-col', {
          'flex-row space-x-4': direction === 'row',
          'flex-col space-y-3': direction === 'column',
        })}
      >
        {options.map((option) => {
          return (
            <div key={option.value} className={cn('inline-flex cursor-pointer items-center')}>
              <RadioGroupPrimitives.Item
                value={option.value}
                id={option.value}
                className="leading-[0px]"
                aria-label={option.label}
              >
                <RadioIndicator size={size} checked={option.value === value} disabled={option.disabled} />
              </RadioGroupPrimitives.Item>
              <label
                htmlFor={option.value}
                className={cn('cursor-pointer pl-2 text-body-m text-text-grey-standard', {
                  'font-bold': option.bold,
                  'pointer-events-none cursor-not-allowed text-text-grey-light': option.disabled,
                })}
              >
                {option.label}
              </label>
            </div>
          );
        })}
      </div>
    </RadioGroupPrimitives.Root>
  );
};
