import { PropsWithChildren } from 'react';

import { cn } from '@/utils/styles.utils';

import styles from './Ul.module.css';

export const Ul = ({ children, className }: PropsWithChildren & { className?: string }) => {
  return <ul className={className}>{children}</ul>;
};

export const Li = ({ children }: PropsWithChildren) => {
  return <li className={cn('relative inline-flex pl-4', styles.li)}>{children}</li>;
};
