import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { NULL_VALUE } from '@/constants/generic.constants';

type Option = { value: string; label: string; disabled?: boolean; options?: never };
type Group = { label: string; options: Option[] };
type OptionOrGroup = Option | Group;

export type NativeSelectProps = {
  id?: string;
  value: string | undefined;
  options: OptionOrGroup[];
  allowNull?: boolean;
  onChange: (value: string | undefined) => void;
  nullOptionLabel?: string;
  disabled?: boolean;
  className?: string;
  label?: string;
};

export const NativeSelectWrapper = (props: NativeSelectProps & { children: React.ReactNode }) => {
  const { children, ...restProps } = props;
  return (
    <div className="relative">
      {children}
      <NativeSelect
        {...restProps}
        className="absolute inset-0 z-[0] w-full cursor-pointer px-4 py-0 opacity-0 laptop:[font-size:15px]"
      />
    </div>
  );
};

export const NativeSelect = (props: NativeSelectProps) => {
  const { value, onChange, label, id, options, allowNull = true, nullOptionLabel, disabled = false, className } = props;
  const selectRef = useRef<HTMLSelectElement>(null);
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === NULL_VALUE) {
      return onChange(undefined);
    }
    onChange(e.target.value);
  };

  return (
    <select
      id={id}
      className={className}
      value={value}
      onChange={handleChange}
      disabled={disabled}
      ref={selectRef}
      aria-label={label}
    >
      {allowNull && (
        <option className="bg-white text-initial" value={NULL_VALUE}>
          {nullOptionLabel || <FormattedMessage id={'select.nullOptionLabel'} />}
        </option>
      )}
      {options.map((option) =>
        option.options ? (
          <optgroup key={option.label} label={option.label} className="bg-white text-initial">
            {option.options.map((option) => (
              <option
                className="bg-white text-initial"
                key={option.value}
                value={option.value}
                selected={option.value === value}
                disabled={option.disabled}
              >
                {option.label}
              </option>
            ))}
          </optgroup>
        ) : (
          <option
            className="bg-white text-initial"
            key={option.value}
            value={option.value}
            selected={option.value === value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ),
      )}
    </select>
  );
};
